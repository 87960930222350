import loadable from '@loadable/component'
import React, {useEffect, useState} from 'react'

const loadableWithDelay = (importFunc, delay = 200, options = {}) =>
  loadable(
    () =>
      new Promise(resolve => {
        setTimeout(() => resolve(importFunc()), delay)
      }),
    options,
  )

export default loadableWithDelay
