import * as R from 'ramda'
import loadable from '@loadable/component'
import React from 'react'

import HeroComponent from 'components/UI/Hero/HeroComponent'
import loadableWithDelay from 'components/UI/LoadableWithDelay'
import LoadingFallback from 'components/UI/LoadingFallback'
// import MediaComponent from 'components/UI/MediaComponent'
import Section from 'components/UI/Section'

const MediaComponent = loadable(() => import('components/UI/MediaComponent'))
const Carousel = loadable(() => import('components/UI/Carousel'))
const PageContentSection = loadable(() =>
  import('components/UI/PageContentSection'),
)

export const DefaultComponent = () => <div>DefaultComponent</div>

export const componentSwitcher = (type, content, items, component) => {
  const {
    hasGreyBackground,
    hasRoundCorner,
    variant,
    surtitle,
    title,
    media,
    thumbnail,
    sectionId,
    callToAction,
    richText,
    backLink,
    variation,
    rawDescription,
  } = component

  const description = R.pathOr('', ['description', 'description'], component)

  switch (type) {
    case 'ContentfulHero':
      return (
        <Section
          hasPaddingTop={false}
          hasPaddingBottom={component.hasPaddingBottom}
          hasVerySmallPadding={component.hasVerySmallPadding}
          id={sectionId}
          hasGreyBackground={hasGreyBackground}
        >
          <HeroComponent
            surtitle={surtitle}
            title={title}
            description={description}
            media={media}
            thumbnail={thumbnail}
            callToAction={callToAction}
            content={content}
            variant={variant}
            descriptionType="description"
            titleType="title"
            isAlignCenter
            hasRoundCorner={hasRoundCorner}
            items={items}
            backLink={backLink}
          />
        </Section>
      )

    case 'ContentfulMediaComponent':
      return (
        <Section
          id={component.sectionId}
          hasBorderBottom={component.hasBorderBottom}
          hasGreyBackground={component.hasGreyBackground}
          hasPaddingBottom={component.hasPaddingBottom || false}
          // hasPaddingSide={component.hasPaddingSide}
          hasPaddingTop={component.hasPaddingTop || false}
          hasRichTextPadding={component.hasRichTextPadding}
          hasSmallPadding={component.hasSmallPadding}
          hasSmallWidth={component.hasSmallWidth}
          hasVerySmallPadding={component.hasVerySmallPadding}
          noSideWrapper={component.noSideWrapper}
        >
          <MediaComponent
            surtitle={surtitle}
            title={title}
            description={description}
            media={media}
            callToAction={callToAction}
            variant={variant}
            content={content}
            items={items}
            richText={richText}
          />
        </Section>
      )

    case 'ContentfulCarousel':
      if (variant === 'Nearshoring expertise') {
        return (
          <Carousel
            surtitle={surtitle}
            title={title}
            description={description}
            media={media}
            callToAction={callToAction}
            variant={variant}
            content={content}
            items={items}
          />
        )
      }

      return (
        <Section
          id={component.sectionId}
          hasBorderBottom={component.hasBorderBottom}
          hasGreyBackground={hasGreyBackground}
          hasPaddingBottom={component.hasPaddingBottom}
          // hasPaddingSide={component.hasPaddingSide}
          hasPaddingTop={component.hasPaddingTop}
          hasRichTextPadding={component.hasRichTextPadding}
          hasSmallPadding={component.hasSmallPadding}
          hasSmallWidth={component.hasSmallWidth}
          hasVerySmallPadding={component.hasVerySmallPadding}
          styles={{
            zIndex: 0,
          }}
        >
          <Carousel
            variant={variant}
            surtitle={surtitle}
            title={title}
            description={description}
            media={media}
            callToAction={callToAction}
            items={items}
            content={content}
          />
        </Section>
      )
    case 'ContentfulContentSection':
      return (
        <Section
          id={component.sectionId}
          hasBorderBottom={component.hasBorderBottom}
          hasGreyBackground={hasGreyBackground}
          hasPaddingBottom={component.hasPaddingBottom}
          // hasPaddingSide={component.hasPaddingSide}
          hasPaddingTop={component.hasPaddingTop}
          hasRichTextPadding={component.hasRichTextPadding}
          hasSmallPadding={component.hasSmallPadding}
          hasSmallWidth={component.hasSmallWidth}
          hasVerySmallPadding={component.hasVerySmallPadding}
        >
          <PageContentSection
            title={title}
            callToAction={callToAction}
            variation={variation}
            description={rawDescription}
            image={media}
          />
        </Section>
      )

    default:
      return DefaultComponent()
  }
}
