import {useEffect, useRef} from 'react'

const isValidSelector = selector => {
  try {
    document.createDocumentFragment().querySelector(selector)
  } catch {
    return false
  }

  return true
}

const useScrollIntoView = hash => {
  const ref = useRef(null)

  useEffect(() => {
    if (!hash || !isValidSelector(hash)) {
      return
    }

    const domElement = document.querySelector(hash)

    if (domElement) {
      ref.current = domElement
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({
            alignToTop: false,
            behavior: 'smooth',
            block: 'center',
          })
        }
      }, 0)
    }
  }, [hash])

  return ref
}

export default useScrollIntoView
