import * as R from 'ramda'
import React from 'react'

import {componentSwitcher} from 'helpers/componentSwitcher'

const SectionComponent = ({sections}) => {
  const mapIndexed = R.addIndex(R.map)

  return (
    <div>
      {sections && (
        <div>
          {mapIndexed((component, index) => {
            const {
              __typename: componentType,
              content: componentContent,
              variant: componentVariant,
              listItems: componentItems,
            } = component

            return (
              <div key={index}>
                {componentSwitcher(
                  componentType,
                  componentContent,
                  componentItems,
                  component,
                )}
              </div>
            )
          }, sections)}
        </div>
      )}
    </div>
  )
}

export default SectionComponent
