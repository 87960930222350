import * as R from 'ramda'
import {heroPropTypes} from 'helpers/propTypes'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React, {useState} from 'react'

const BoxHero = loadable(() => import('./BoxHero'))
const WideHero = loadable(() => import('./WideHero'))
const NearshoringHero = loadable(() => import('./NearshoringHero'))
const DefaultHero = loadable(() => import('./DefaultHero'))
const TestimonialHero = loadable(() => import('./TestimonialHero'))
const NavigationalHero = loadable(() => import('./NavigationalHero'))

const HeroComponent = ({
  ctaColor,
  descriptionType,
  hasCtaOnRight,
  hasRoundCorner,
  hasSmallVerticalMargin,
  isAlignCenter,
  offsetBottom,
  titleType,
  hasAutoWidth,
  content,
  variant,
  items,
  surtitle,
  title,
  description,
  media,
  thumbnail,
  callToAction,
  backLink,
}) => {
  const mediaType = R.pathOr('', ['file', 'contentType'], media)

  const [isModalOpen, setModalOpen] = useState(false)

  const handleOpenModal = () => {
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
  }

  return (
    <>
      {variant === 'Navigational Hero' && (
        <NavigationalHero
          backLink={backLink}
          media={media}
          surtitle={surtitle}
          title={title}
          description={description}
        />
      )}

      {variant === 'Box' && (
        <BoxHero
          longDescription={description}
          parallaxMediaType="Image"
          media={media}
          surtitle={surtitle}
          title={title}
          description={description}
          callToAction={callToAction}
          titleVariant="h2"
        />
      )}
      {variant === 'Nearshoring Hero' && (
        <NearshoringHero
          ctaColor={ctaColor}
          descriptionType={descriptionType}
          hasCtaOnRight={hasCtaOnRight}
          hasRoundCorner={hasRoundCorner}
          hasSmallVerticalMargin={hasSmallVerticalMargin}
          isAlignCenter={isAlignCenter}
          offsetBottom={offsetBottom}
          titleType={titleType}
          hasAutoWidth={hasAutoWidth}
          // content={content}
          mediaType={mediaType}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          surtitle={surtitle}
          title={title}
          description={description}
          media={media}
          callToAction={callToAction}
        />
      )}
      {variant === 'Wide Hero' && (
        <WideHero
          ctaColor={ctaColor}
          descriptionType={descriptionType}
          hasCtaOnRight={hasCtaOnRight}
          hasRoundCorner={hasRoundCorner}
          hasSmallVerticalMargin={hasSmallVerticalMargin}
          isAlignCenter={isAlignCenter}
          offsetBottom={offsetBottom}
          titleType={titleType}
          hasAutoWidth={hasAutoWidth}
          content={content}
          mediaType={mediaType}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
        />
      )}
      {variant === 'Testimonial Hero' && (
        <TestimonialHero
          ctaColor={ctaColor}
          descriptionType={descriptionType}
          hasCtaOnRight={hasCtaOnRight}
          hasRoundCorner={hasRoundCorner}
          hasSmallVerticalMargin={hasSmallVerticalMargin}
          isAlignCenter={isAlignCenter}
          offsetBottom={offsetBottom}
          titleType={titleType}
          hasAutoWidth={hasAutoWidth}
          content={content}
          mediaType={mediaType}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          items={items}
          surtitle={surtitle}
          title={title}
          description={description}
          media={media}
          thumbnail={thumbnail}
          callToAction={callToAction}
        />
      )}
      {(variant === 'Default Hero' || !variant) && (
        <DefaultHero
          ctaColor={ctaColor}
          descriptionType={descriptionType}
          hasCtaOnRight={hasCtaOnRight}
          hasRoundCorner={hasRoundCorner}
          hasSmallVerticalMargin={hasSmallVerticalMargin}
          isAlignCenter={isAlignCenter}
          offsetBottom={offsetBottom}
          titleType={titleType}
          hasAutoWidth={hasAutoWidth}
          content={content}
          mediaType={mediaType}
          handleOpenModal={handleOpenModal}
          handleCloseModal={handleCloseModal}
          isModalOpen={isModalOpen}
          surtitle={surtitle}
          title={title}
          description={description}
          media={media}
          thumbnail={thumbnail}
          callToAction={callToAction}
        />
      )}
    </>
  )
}

HeroComponent.propTypes = heroPropTypes.isRequired

HeroComponent.defaultProps = {
  ctaColor: 'primary',
  ctaLink: '',
  ctaTitle: '',
  description: shape({description: ''}),
  descriptionType: 'largeDescription',
  hasCtaOnRight: false,
  hasRoundCorner: false,
  hasSmallVerticalMargin: false,
  hat: '',
  isAlignCenter: false,
  media: null,
  offsetBottom: false,
  title: '',
  titleType: 'largeTitle',
}

export default HeroComponent
